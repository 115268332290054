import type { ProductConfig } from '../../default/product/product.types';
import type { DeepPartial } from '../../default/types';

export const productConfig: DeepPartial<ProductConfig> = {
  cliplister: {
    customerId: '81856',
  },
  flixmedia: {
    distributorId: 18441,
    enabled: true,
  },
  hasKIM: false,
};
